<template>
  <div class="chat-container">
    <vue-advanced-chat
        class="chat"
        height="100%"
        :current-user-id="currentUserId"
        :rooms="JSON.stringify([room])"
        single-room="true"
        show-files="false"
        show-audio="false"
        :auto-scroll="JSON.stringify({send: {new: true, newAfterScrollUp: true}, receive: {new: true, newAfterScrollUp: true}})"
        :messages-loaded="JSON.stringify(messagesLoaded)"
        :messages="JSON.stringify(messages)"
        is-mobile="true"
        :text-messages="JSON.stringify({CONVERSATION_STARTED: 'Conversation started'})"
        @send-message="sendMessage($event.detail[0])"
        @fetch-messages="fetchMessages($event.detail[0])"
    />
  </div>
</template>

<script>
import {register} from 'vue-advanced-chat'
import BaseTemplate from 'armory-sdk/src/templates/BaseTemplate.vue'

register()

export default {
  name: "ChatTemplate",
  extends: BaseTemplate,
  props: {
    connectionId: String,
    initialMessages: {
      type: Array,
      default: () => []
    },
    room: {
      type: Object,
      default: () => ({
        roomId: '1',
        roomName: 'Room 1',
        avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj',
        users: [
          {
            _id: '1234',
            username: 'John Doe'
          },
          {
            _id: '4321',
            username: 'John Snow'
          }
        ]
      })
    },
  },
  data() {
    return {
      currentUserId: this.connectionId,
      messagesLoaded: true
    }
  },
  computed: {
    messages: {
      get() {
        return this.formatMessages(this.$store.state.messages)
      },
      set(value) {
        this.$store.commit("setMessages", value)
      }
    }
  },
  mounted() {
    if (this.messages.length === 0) {
      this.$store.commit("setMessages", this.initialMessages)
    }
    this.messagesLoaded = true

    document.getElementsByClassName('chat')[0].shadowRoot.querySelector('.vac-room-header').remove();
    document.getElementsByClassName('chat')[0].shadowRoot.querySelector('#messages-list').style.margin = "0";
    document.getElementsByClassName('chat')[0].shadowRoot.addEventListener('click', (e) => {
          if (e.target.tagName === 'A' && window.self !== window.top) { // This checks if the chat is inside an iframe and if so, it opens the link in the parent window
            e.preventDefault()
            console.log('Clicked url inside chat', e.target.href)
            window.open(e.target.href, '_parent');
          }
        }
    )
  },
  methods: {
    formatMessages(messages) {
      if (!messages || !messages.length) return []
      return messages.map(message => {
        // console.log("Before", message.timestamp)

        // let timestamp = message.timestamp ? new Date(message.timestamp) : new Date()
        let timestamp = message.timestamp ? new Date(message.timestamp) : new Date()

        message.timestamp = timestamp.toString().substring(16, 21)
        message.date = timestamp.toDateString()
        // console.log("After", message.timestamp)
        return {
          _id: message._id,
          content: message.content,
          senderId: message.senderId,
          // timestamp: message.timestamp, # TODO fix timestamp
          // date: message.date
        }
      })
    },


    fetchMessages({options = {}}) {
      setTimeout(() => {
        this.messagesLoaded = true

        if (options.reset) {
          // this.messages = this.getMessages()
        } else {
          // this.messages = this.getMessages()
        }
      })
    },
    sendMessage(message) {
      console.log("Sending message", JSON.stringify(message))
      let sentMessage = {
        _id: this.messages.length,
        content: message.content,
        senderId: this.currentUserId,
      }

      this.$store.commit('addSentMessage', {message: sentMessage, socket: this.$socket})
      // TODO: Send message to the server
    },
  }
}
</script>
<style>
.chat-container {
  display: flex;
  width: 100%;
  height: 100vh
}

vue-advanced-chat {
  width: 100%;
}
</style>